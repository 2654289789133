import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout'
import Img from 'gatsby-image'

const BlogTop = ({data}) => {
  const longList = data.allMarkdownRemark.edges;
  // const shortList = data.allMarkdownRemark.edges.slice(0,2);
  return (
    <Layout>
    <h1 style={{ padding: '1em' }} className="uppercase center blog-title">Recent Articles</h1>
      {longList.map(post => (
        <div style={{ margin: `1% auto`, maxWidth: 650, padding: `0 1rem` }} key={ post.node.id }>
          <a href={post.node.frontmatter.path} className="art-container">
            <Img className="preview-image" sizes={post.node.frontmatter.featuredImage.childImageSharp.sizes} />
            <div className="img-overlay">
              <h3>{post.node.frontmatter.title}</h3>
              <small>Posted by {post.node.frontmatter.author} on {post.node.frontmatter.date}</small>
            </div>
          </a>
        </div>
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogIndexQuery {
      allMarkdownRemark(
        sort: {
          fields: [frontmatter___date]
          order: DESC
        })
      {
      edges {
        node {
          id
          frontmatter {
            title
            author
            date
            path
            featuredImage {
                childImageSharp{
                    sizes(maxWidth: 630) {
                        ...GatsbyImageSharpSizes
                    }
                }
            }
          }
        }
      }
    }
  }
`

export default BlogTop;
